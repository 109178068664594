import React from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getCommentActivitiesV1 } from 'api/comment'
import { clientFetch } from 'lib/fetch/client'
import { fetchCommentsDetail } from 'server-actions/fetch-comments'
import { useLocale } from 'next-intl'
import { useRefreshProfilePageStore } from 'store/global/refresh-page'
import { type CommentFilterTypeEnum } from 'type/comment'

interface IProfileCommentProp {
  userId: string
  filter_type: CommentFilterTypeEnum
}

const useProfileComment = ({ userId, filter_type }: IProfileCommentProp) => {
  const refreshingId = useRefreshProfilePageStore(state => state.refreshingId)
  const SIZE = 10

  const {
    data: { comments = [] } = {},
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isLoading: isInitialLoading,
    isFetching
  } = useInfiniteQuery({
    queryKey: ['user/activities', userId, refreshingId, filter_type],
    initialPageParam: '',
    queryFn: async ({ pageParam }) => {
      try {
        const searchParams = new URLSearchParams({
          user_id: userId,
          size: String(SIZE),
          filter_type: filter_type
        })

        if (pageParam) {
          searchParams.append('page_token', pageParam)
        }

        const { data } = await getCommentActivitiesV1(clientFetch, searchParams)

        if (data.comments.length === 0) {
          return {
            comments: [],
            pageToken: undefined
          }
        }

        const comments = await fetchCommentsDetail(data.comments)

        return {
          comments: comments,
          pageToken: data.next_page_token || undefined
        }
      } catch (error) {
        return {
          comments: [],
          pageToken: undefined
        }
      }
    },
    select(data) {
      return {
        comments: data.pages.flatMap(item => item.comments)
      }
    },
    getNextPageParam: lastPage => {
      return lastPage.pageToken
    }
  })

  const noMoreData = !isInitialLoading && !hasNextPage

  return {
    comments,
    isFetchingNextPage,
    noMoreData,
    fetchNextPage,
    isInitialLoading,
    isFetching
  }
}

export default useProfileComment
