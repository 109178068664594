import { API_V2 } from 'constant/apiUrl'
import type { Fetch } from 'lib/fetch/type'
import type { IRespV2 } from 'type/common'
import type { ISpotV2 } from 'type/spot'
import type { IUserV2 } from 'type/user'

// V2
export const getUserV2 = async (
  fetcher: Fetch,
  id: string
): Promise<IRespV2<IUserV2>> => {
  const res = await fetcher(API_V2.USER + `/user/${id}`)

  return res
}

export const deleteUserV2 = async (
  fetcher: Fetch,
  req: { text: string }
): Promise<IRespV2<{ query: string }>> => {
  const res = await fetcher(API_V2.USER + `/user/`, {
    method: 'DELETE',
    body: req // *  fake payload
  })

  return res
}

export const getUsersV2 = async (
  fetcher: Fetch,
  ids: string
): Promise<IRespV2<IUserV2[]>> => {
  const res = await fetcher(API_V2.USER + `/user/users?ids=${ids}`)

  return res
}

/**
 *
 * @param query ex: page=1&page_size=12&page_token=xxx
 */
export const getUserSpotsV2 = async (
  fetcher: Fetch,
  id: string,
  query: string
): Promise<
  IRespV2<{
    spots: ISpotV2[]
    next_page_token?: string
  }>
> => {
  const res = await fetcher(API_V2.CONTENT + `/user/${id}/spots?${query}`)

  return res
}

export const resetPasswordV2 = async (
  fetcher: Fetch,
  req: {
    email: string
    password: string
    time_key: string
    user_id: string
  }
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.USER + `/user/password/reset/callback`, {
    method: 'POST',
    body: req
  })

  return res
}

export const requestResetPasswordV2 = async (
  fetcher: Fetch,
  req: {
    email: string
  }
): Promise<IRespV2> => {
  const res = await fetcher(API_V2.USER + `/user/password/reset`, {
    method: 'POST',
    body: req
  })

  return res
}

export const checkEmailV2 = async (
  fetcher: Fetch,
  email: string
): Promise<IRespV2<{ available: boolean }>> => {
  const res = await fetcher(API_V2.USER + `/user/email/check?email=${email}`)

  return res
}

export const checkUidV2 = async (
  fetcher: Fetch,
  uid: string
): Promise<IRespV2<{ available: boolean }>> => {
  const res = await fetcher(API_V2.USER + `/user/uid/check?uid=${uid}`)

  return res
}

export const followUserV2 = async (
  fetcher: Fetch,
  req: {
    user_id: string
  }
): Promise<IRespV2<{ followed: boolean }>> => {
  const res = await fetcher(API_V2.USER + `/user/follow`, {
    method: 'POST',
    body: req
  })

  return res
}

export const unfollowUserV2 = async (
  fetcher: Fetch,
  req: {
    user_id: string
  }
): Promise<IRespV2<{ followed: boolean }>> => {
  const res = await fetcher(API_V2.USER + `/user/follow`, {
    method: 'DELETE',
    body: req
  })

  return res
}

/**
 *
 * @param query `code=xxx`
 * @returns
 */
export const shareByCode = async (
  fetcher: Fetch,
  query: string
): Promise<IRespV2> => {
  const res = await fetcher(
    API_V2.USER + `/user/share/share-by-code?${query}`,
    {
      method: 'POST',
      ignoreResponseError: true
    }
  )

  return res
}
