import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'

import IconSend from 'asset/icon/send.svg'
import cn from 'util/cn'

interface INoMyActivity extends ComponentPropsWithoutRef<'div'> {}

const NoMyActivity = ({ ...props }: INoMyActivity) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className={cn(
        'flex flex-col items-center justify-center',
        props.className
      )}
    >
      <div className='mb-[16px] flex h-[88px] w-[88px] items-center justify-center rounded-full bg-playseeGray-gray4'>
        <IconSend width={60} height={60} className='fill-white' />
      </div>
      <h3 className='m-0 mb-[4px] p-0 text-md font-semibold text-label-l1'>
        {t('profile.no_posts_yet')}
      </h3>
      <p className='text-md text-label-l2'>
        {t('profile.your_post_will_appear_here')}
      </p>
    </div>
  )
}

export default NoMyActivity
