'use client'
import { useTranslations } from 'next-intl'
import { memo } from 'react'

import type { IProfileCountV2 } from 'type/profile'
import numberFormat from 'util/numberFormat'

interface IFollowStatus {
  count: IProfileCountV2
}
const FollowStatus = (props: IFollowStatus) => {
  const { count } = props

  const t = useTranslations()
  const followersNumber = count.follower
  const followingNumber = count.following + count.following_hashtag

  return (
    <div className='flex flex-grow text-center text-label-l1'>
      <div className='flex flex-grow flex-col items-center'>
        <div className='w-fit cursor-pointer'>
          <div className='text-center text-2xl font-semibold tabular-nums'>
            {numberFormat(followersNumber, 'compact')}
          </div>
          <div className='text-sm'>
            {followersNumber === 1
              ? t('common.follower')
              : t('common.followers')}
          </div>
        </div>
      </div>
      <div className='flex flex-grow flex-col items-center'>
        <div className='w-fit cursor-pointer'>
          <div className='text-center text-2xl font-semibold tabular-nums text-label-l1'>
            {numberFormat(followingNumber, 'compact')}
          </div>
          <div className='text-sm'>{t('common.following')}</div>
        </div>
      </div>
      <div className='flex flex-grow flex-col items-center'>
        <div className='w-fit cursor-pointer'>
          <div className='text-center text-2xl font-semibold tabular-nums text-label-l1'>
            {numberFormat(count.joined, 'compact')}
          </div>
          <div className='text-sm'>{t('profile.joined')}</div>
        </div>
      </div>
    </div>
  )
}

export default memo(FollowStatus)
