import { useTranslations } from 'next-intl'
import { type ComponentPropsWithoutRef } from 'react'

const NoMedia = (props: ComponentPropsWithoutRef<'div'>) => {
  const t = useTranslations()

  return (
    <div
      {...props}
      className='py-[32px] px-[24px] flex flex-col items-center justify-center'
    >
      <div className='sq-[88px] mb-[16px] flex rounded-full bg-playseeGray-gray4 text-playseeGray-gray5'>
        <div
          className='i-ps-play_s_big [--icon-size:60px] m-auto'
          role='img'
          aria-label='No recent comments'
        />
      </div>
      <p className='font-semibold mb-[4px] whitespace-pre-wrap'>
        {t('media.no_media_yet')}
      </p>
      <p className='text-label-l2'>
        {t('profile.your_media_will_appear_here')}
      </p>
    </div>
  )
}

export default NoMedia
