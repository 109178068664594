'use client'

import NoSpot from 'component/block/no-content/spot'
import ScrollLoad from 'component/block/scrollLoad'
import { POPUP_TRIGGER_HEIGHT } from 'constant'
import useScrollingCallback from 'hook/useScrollingCallback'
import { usePopupStore } from 'store/global/popup'
import { useDeviceStore } from 'store/server-context/device'
import { normalizeAsset } from 'util/normalizeAsset'
import SuggestedPostList from './profileSuggestedList/suggestedMediaList'
import SmallMediaThumbnail, {
  MediaFeedTypeEnum
} from '../media/small-media/thumbnail-link'
import { formatMediaAsset, formatMediaThumbnailType } from 'util/comment'
import NoMedia from 'component/block/no-content/media'
import useProfileComment from './use-profile-comment'
import { CommentFilterTypeEnum } from 'type/comment'
import { useInitialMediaStore } from 'store/global/media'

interface IUserSpots {
  userId: string
  userUid: string
  isMe: boolean
}
const UserMedia = (props: IUserSpots) => {
  const { userId, userUid, isMe } = props

  const isOpen = usePopupStore(state => state.mask.download)
  const showPopup = usePopupStore(state => state.showDownloadPopup)
  const device = useDeviceStore(state => state.device)
  const setInitComment = useInitialMediaStore(state => state.setInitComment)

  useScrollingCallback({
    callback: showPopup,
    scrollDistance: POPUP_TRIGGER_HEIGHT.post,
    enabled: device !== 'desktop' && !isOpen
  })
  const {
    comments,
    isFetchingNextPage,
    noMoreData,
    isInitialLoading,
    isFetching,
    fetchNextPage
  } = useProfileComment({
    userId,
    filter_type: CommentFilterTypeEnum.Media
  })

  const handleScroll = () => {
    fetchNextPage()
  }

  return (
    <div>
      <ScrollLoad
        callback={handleScroll}
        fetching={isFetchingNextPage || isInitialLoading || isFetching}
        fetchEnd={noMoreData}
      >
        <div className='mt-[1px] min768:my-[12px]'>
          {!isInitialLoading && (
            <>
              {comments.length === 0 &&
                (isMe ? (
                  <NoMedia />
                ) : (
                  <NoSpot uid={userUid} className='mt-[24px]' />
                ))}

              {comments.length > 0 && (
                <div className='relative mx-auto w-full'>
                  <div className='grid w-full grid-cols-3 gap-[1px] min768:gap-[12px]'>
                    {comments.map((comment, index) => {
                      const { comment: main } = comment
                      const media = main.media?.[0]
                      const asset = formatMediaAsset(media)
                      const { src } = normalizeAsset(asset)
                      return (
                        <SmallMediaThumbnail
                          key={`${main.id}_${index}`}
                          coverUrl={src}
                          desc={main.text || ''}
                          thumbnailType={formatMediaThumbnailType(asset)}
                          mediaFeedTypeType={MediaFeedTypeEnum.Profile}
                          linkId={userId}
                          onClick={() => {
                            setInitComment(main)
                          }}
                        />
                      )
                    })}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </ScrollLoad>
      {!isMe && !isInitialLoading && noMoreData && (
        <SuggestedPostList userId={userId} />
      )}
    </div>
  )
}

export default UserMedia
