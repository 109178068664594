import linkifyHtml from 'linkify-html'

const filterUrls = (description: string) => {
  return linkifyHtml(description || '', {
    render: {
      url: () => '',
      mention: ({ content }) => content,
      hashtag: ({ content }) => content
    }
  })
}

export default filterUrls
