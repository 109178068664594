import { API_V2 } from 'constant/apiUrl'
import type { Fetch } from 'lib/fetch/type'
import type { ICommentV2 } from 'type/comment'
import type { IRespV1, IRespV2 } from 'type/common'

export const getCommentV2 = async (
  fetcher: Fetch,
  id: string
): Promise<IRespV2<ICommentV2>> => {
  const res = await fetcher(API_V2.CONTENT + `/comment/${id}`)

  return res
}

// page_size=12&next_page_token=xxx
export const getCommentReply = async (
  fetcher: Fetch,
  id: string,
  query: string
): Promise<
  IRespV2<{
    comments: ICommentV2[]
    next_page_token?: string
  }>
> => {
  const res = await fetcher(API_V2.CONTENT + `/comment/${id}/reply?${query}`)

  return res
}

export const getCommentsV2 = async (
  fetcher: Fetch,
  ids: string,
  lang: string = 'en'
): Promise<IRespV2<ICommentV2[]>> => {
  const res = await fetcher(API_V2.CONTENT + `/comment?ids=${ids}&lang=${lang}`)

  return res
}

/**
 * @param query
 * lang=en&size=20
 */
export const getCommentReferencesV2 = async (
  fetcher: Fetch,
  id: string,
  query?: string
): Promise<IRespV2<{ comments: ICommentV2[]; next_page_token?: string }>> => {
  const res = await fetcher(
    API_V2.CONTENT_V2 + `/comment/${id}/references?${query}`
  )

  return res
}

/**
 * @param query ?user_id=1863565599834738688&size=10&page_token=eyJzaXplIjoxMCwib2Zmc2V0IjoxMH0%3D&lang=en
 * @description
 * user_id: 查看 activities 的對象。如果沒帶等同查看自己的。
 */
export const getCommentActivitiesV1 = async (
  fetcher: Fetch,
  query: string | URLSearchParams
): Promise<
  IRespV1<{
    comments: ICommentV2[]
    next_page_token: string
  }>
> => {
  const url = new URL(API_V2.CONTENT + `/comment/activities`)
  url.search = `?${query}`
  const res = await fetcher(url.toString())
  return res
}
