export interface IProfileUserV2 {
  email_verified: number
  follow_status: string // "followed", "requested", "not_followed"
  headshot_id: string
  is_block: boolean
  is_private: boolean
  level: string
  location: {
    geo_level0: string
    geo_level1: string
    geo_level2: string
    geo_level3: string
  }
  name: string
  uid: string
  user_id: string
}

export interface IProfileBizV2 {
  account_type: string
  action_type: string
  country_code: string
  email: string
  industry: string
  is_potential_official_account: boolean
  phone: string
  time_zone: string
  web: string
}

export interface IProfilePrivateV2 {
  age_legal: boolean
  allow_comment_status: string
  birthday: string
  email: string
  is_facebook_binding: boolean
  is_google_binding: boolean
  password_is_change: boolean
  password_status: number
  prefer_tab: string
  preview: boolean
}

export interface IProfilePublicV2 {
  about: string
  country_code: string
  phone: string
}

export interface IProfileV2 {
  profile: {
    biz_info: IProfileBizV2
    private_info: IProfilePrivateV2
    public_info: IProfilePublicV2
  }
  relation: {
    msg_off_notice: number
    post_attention: number
    user_block: number
  }
  user: IProfileUserV2
}

export interface IProfileCountV2 {
  follower: number
  following: number
  following_hashtag: number
  joined: number
  post: number
  message_request: number
}

export enum TabKey {
  Activities = '1',
  Media = '2'
}
