import { create } from 'zustand'

function mergeNewValue<T>(oldValue: Partial<T>, newValue: Partial<T>) {
  // if new value's key is not in old value, use new value
  // else use old value

  // check keys is equal or not
  const oldKeys = Object.keys(oldValue)
  const newKeys = Object.keys(newValue)
  if (
    new Set([...oldKeys, ...newKeys]).size ===
    (oldKeys.length + newKeys.length) / 2
  ) {
    return oldValue
  }

  const result: Partial<T> = {}
  for (const key in newValue) {
    if (key in oldValue && oldValue[key] !== undefined) {
      result[key] = oldValue[key]
    } else {
      result[key] = newValue[key]
    }
  }

  return result
}

export function globalStoreCreator<Value>() {
  const useGlobalStore = create<Record<string, Value | undefined>>(
    () => ({}) as any
  )

  function useState(id: string, defaultState: Partial<Value>) {
    const state = useGlobalStore(state => state[id])

    if (!state) {
      useGlobalStore.setState({ [id]: defaultState } as any)
      return defaultState
    }

    return mergeNewValue(state, defaultState)
  }

  function partialUpdateState(id: string, state: Partial<Value>) {
    const oldState = useGlobalStore.getState()[id]
    if (!oldState) return

    useGlobalStore.setState({ [id]: { ...oldState, ...state } })
  }

  return { useState, partialUpdateState }
}
