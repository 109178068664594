import { useTranslations } from 'next-intl'

import IconRight from 'asset/icon/arrow_right.svg'
import LanguageSelector from 'component/block/language-selector'
import Link from 'component/block/link'
import { DialogTitle } from 'component/ui/dialog'
import { LEGAL_LINKS, PLAYSEE_LINKS } from 'constant'
import { usePopupStore } from 'store/global/popup'

const list = [
  { webKey: 'common.about', href: PLAYSEE_LINKS.about },
  { webKey: 'common.business', href: PLAYSEE_LINKS.business },
  { webKey: 'common.help_center', href: PLAYSEE_LINKS.help },
  { webKey: 'common.careers', href: PLAYSEE_LINKS.careers },
  { webKey: 'common.playsee_user_terms_of_service', href: LEGAL_LINKS.terms },
  { webKey: 'common.playsee_privacy_notice', href: LEGAL_LINKS.privacy }
]

const ProfileSetting = () => {
  const t = useTranslations()
  const showPopup = usePopupStore(state => state.showAccountPopup)
  return (
    <div>
      <DialogTitle className='mb-[12px] text-center text-lg font-semibold'>
        {t('profile.me.setting')}
      </DialogTitle>
      <ul className='flex w-full flex-col text-label-l1'>
        <li>
          <a
            onClick={showPopup}
            className='flex items-center justify-between p-[16px] font-medium'
          >
            <span>{t('auth.form.account')}</span>
            <IconRight width={18} height={18} />
          </a>
        </li>
        <hr />
        {list.map((item, i) => (
          <li key={i}>
            <Link
              href={item.href}
              target='_blank'
              className='flex items-center justify-between p-[16px] font-medium'
            >
              {/* @ts-expect-error */}
              <span>{t(item.webKey)}</span>
              <IconRight width={18} height={18} />
            </Link>
          </li>
        ))}
      </ul>
      <LanguageSelector className='ml-auto mt-[16px] inline-block w-[200px] rounded-[12px] border-label-l3 px-[14px] py-[11px] text-md text-label-l1' />
    </div>
  )
}

export default ProfileSetting
