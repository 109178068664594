'use client'
import NoActivity from 'component/block/no-content/activity'
import ScrollLoad from 'component/block/scrollLoad'
import useScrollingCallback from 'hook/useScrollingCallback'
import { POPUP_TRIGGER_HEIGHT } from 'constant'
import { type IAssetV2 } from 'type/asset'
import { useDeviceStore } from 'store/server-context/device'
import { usePopupStore } from 'store/global/popup'
import NoMyActivity from 'component/block/no-content/my-activity'
import useProfileComment from './use-profile-comment'
import { CommentFilterTypeEnum } from 'type/comment'
import CommunityWithComments from '../community/community-with-comments'
import useKeydownToggleVideo from 'hook/useKeydownToggleVideo'

interface IUserSpots {
  userId: string
  userUid: string
  userName: string
  headshot: IAssetV2 | undefined | null
  isMe: boolean
}

const UserActivities = (props: IUserSpots) => {
  const { userId, userUid, isMe } = props
  const device = useDeviceStore(state => state.device)
  const isOpen = usePopupStore(state => state.mask.download)
  const showPopup = usePopupStore(state => state.showDownloadPopup)
  useKeydownToggleVideo()
  const {
    comments,
    isFetchingNextPage,
    noMoreData,
    isInitialLoading,
    isFetching,
    fetchNextPage
  } = useProfileComment({
    userId,
    filter_type: CommentFilterTypeEnum.All
  })

  const handleScroll = () => {
    fetchNextPage()
  }

  useScrollingCallback({
    callback: showPopup,
    scrollDistance: POPUP_TRIGGER_HEIGHT.post,
    enabled: device !== 'desktop' && !isOpen
  })

  return (
    <>
      <div>
        {comments?.length === 0 && !isInitialLoading ? (
          isMe ? (
            <NoMyActivity className='mt-16' />
          ) : (
            <NoActivity uid={userUid} className='mt-[24px]' />
          )
        ) : (
          <div className='relative mx-auto w-full'>
            <ScrollLoad
              callback={handleScroll}
              fetching={isInitialLoading || isFetchingNextPage || isFetching}
              fetchEnd={noMoreData}
            >
              {comments.map((comment, index) => (
                <CommunityWithComments
                  key={`${comment.comment.id}_${index}`}
                  comment={comment}
                  isSubComment={false}
                />
              ))}
            </ScrollLoad>
          </div>
        )}
      </div>
    </>
  )
}

export default UserActivities
